<template>
  <div class="app-container">
    <el-page-header v-if="isShowBack === '1'" @back="goBack" content="补录订单页面"></el-page-header>
    <el-form :model="queryParams" :rules="searchRules" ref="queryForm" :inline="true" label-width="68px">

      <el-form-item label="进度状态" prop="findType">
        <el-select v-model="queryParams.findType" size="mini" @change="getList">
          <el-option v-for="item in findTypeList" :key="item.value" :value="item.value"
                     :label="item.label"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="状态筛选" prop="status">
        <el-select v-model="queryParams.status" size="mini" :clearable="true">
          <el-option v-for="(item,index) in orderStatusList" :key="index" :value="item.value"
                     :label="item.label"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="提交人" prop="submitId">
        <el-select v-model="queryParams.submitId" placeholder="选择提交人" filterable clearable size="small">
          <!--            <el-option label="选择顾问" value=""/>-->
          <el-option
              v-for="ad in adviserList"
              :value="ad.id"
              :key="ad.id"
              :label="ad.nickName"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="手机号" prop="phone">
        <el-input v-model.trim="queryParams.phone" placeholder="请输入手机号" clearable size="mini"/>
      </el-form-item>

      <el-form-item label="机会ID" prop="opporId">
        <el-input v-model.trim="queryParams.opporId" placeholder="请输入机会ID" clearable size="mini"
                  type="number"/>
      </el-form-item>

      <el-form-item label="支付单号" prop="orderNo">
        <el-input v-model="queryParams.orderNo" placeholder="支付单号" clearable size="mini"/>
      </el-form-item>

      <el-form-item label="商品名称" prop="goodsName">
        <el-input v-model="queryParams.goodsName" placeholder="请输入商品名称" clearable size="mini"/>
      </el-form-item>

      <el-form-item label="收款账号" prop="payAccount">
        <el-select v-model="queryParams.payAccount" size="mini" :clearable="true">
          <el-option v-for="item in payAccountArr" :key="item.id" :value="item.dictValue"
                     :label="item.dictLabel"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="部门筛选" prop="deptId">
        <el-cascader
            v-model="queryParams.deptId"
            :options="deptArr"
            :props="{ checkStrictly: true, emitPath: false, value: 'id', label:'deptName'}"
            clearable
            size="mini"></el-cascader>
      </el-form-item>

      <el-form-item label="支付时间" prop="payTimeArr">
        <el-date-picker
            v-model="payTimeArr"
            clearable
            size="mini"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd">
        </el-date-picker>
      </el-form-item>

        <el-form-item label="参加活动" prop="joinActivity">
            <el-select v-model="queryParams.joinActivity" size="mini" :clearable="true">
                <el-option :value="''" label="全部"/>
                <el-option :value="true" label="参加"/>
                <el-option :value="false" label="未参加"/>
            </el-select>
        </el-form-item>

        <el-form-item label="是否手动开课" prop="handleOpenCourse" label-width="200">
            <el-select v-model="queryParams.handleOpenCourse" size="mini" :clearable="true">
                <el-option :value="''" label="全部"/>
                <el-option :value="true" label="是"/>
                <el-option :value="false" label="否"/>
            </el-select>
        </el-form-item>

      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-button type="success" size="mini" @click="showDetail">添加</el-button>
      <el-button v-permission="'crm:repair:import'" type="primary" size="mini" @click="importOrderRepair">导入
      </el-button>
      <el-button v-permission="'crm:repair:export'" type="primary" size="mini" @click="exportOrderRepair">导出
      </el-button>
    </el-row>

    <el-table max-height="600" v-loading="loading" :data="repairList">
      <el-table-column label="支付单号" align="center" prop="orderNo" width="250" v-if="this.classifyData.orderNo  && this.classifyData.orderNo.status" :key="1">
        <template v-slot="scope">
          {{ scope.row.orderNo }}
        </template>
      </el-table-column>
      <el-table-column label="机会ID" align="center" prop="opporId" width="100" v-if="this.classifyData.opporId && this.classifyData.opporId.status" :key="2"/>
      <el-table-column label="手机号" align="center" prop="phone" width="110" v-if="this.classifyData.phone  && this.classifyData.phone.status" :key="3"/>
      <el-table-column label="商品名称" align="center" prop="goodsName" width="200" v-if="this.classifyData.goodsIds && this.classifyData.goodsIds.status" :key="4"/>
      <el-table-column label="原价" align="center" prop="price" width="100" v-if="this.classifyData.price && this.classifyData.price.status" :key="5">
        <template v-slot="scope">
          {{ numberformat(scope.row.price / 100) }}
        </template>
      </el-table-column>
      <el-table-column label="支付金额" align="center" prop="payPrice" width="100" v-if="this.classifyData.payPrice  && this.classifyData.payPrice.status" :key="6">
        <template v-slot="scope">
          {{ numberformat(scope.row.payPrice / 100) }}
        </template>
      </el-table-column>
      <el-table-column label="归属学习顾问" align="center" prop="adviserId" width="80" v-if="this.classifyData.adviserId  && this.classifyData.adviserId.status" :key="7">
        <template v-slot="scope">
          <el-tag effect="plain" :disable-transitions="true" v-for="item in accountList" :key="item.id"
                  v-if="scope.row.adviserId === item.id">
            {{ item.nickName }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column label="提交人" align="center" prop="submitId" width="100"  :key="8">
        <template v-slot="scope">
          <el-tag :disable-transitions="true" effect="dark" v-for="item in accountList" :key="item.id"
                  v-if="scope.row.submitId === item.id">
            {{ item.nickName }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column label="支付时间" align="center" prop="payTime" type="date" width="100" v-if="this.classifyData.payTime  && this.classifyData.payTime.status" :key="9"/>
      <el-table-column label="状态" align="center" prop="status" width="100" :key="10">
        <template v-slot="scope">
          <el-tag :disable-transitions="true" v-for="item in orderStatusList" :key="item.value"
                  :type="item.type"
                  effect="dark"
                  v-if="scope.row.status === item.value">
            {{ item.label }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column label="缴费方式" align="center" prop="payType" v-if="this.classifyData.payType  && this.classifyData.payType.status" :key="11">
        <template v-slot="scope">
          <el-tag :disable-transitions="true" v-for="item in collectionWayList" :key="item.id"
                  v-if="scope.row.payType === item.dictValue">
            {{ item.dictLabel }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="订单渠道" align="center" prop="orderChannel" v-if="this.classifyData.orderChannel  && this.classifyData.orderChannel.status == true" :key="12">
        <template v-slot="scope">
          <el-tag :disable-transitions="true" type="warning" v-for="item in orderChannelList" :key="item.id"
                  v-if="scope.row.orderChannel === item.dictValue">
            {{ item.dictLabel }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="收款账号" align="center" prop="payAccount" v-if="this.classifyData.payAccount  && this.classifyData.payAccount.status" :key="13">
        <template v-slot="scope">
          <el-tag :disable-transitions="true" type="success" v-for="item in payAccountArr" :key="item.id"
                  v-if="scope.row.payAccount + '' === item.dictValue">
            {{ item.dictLabel }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="订单创建时间" align="center" prop="orderCreateTime" width="150" :sortable="true"/>
      <el-table-column label="一审通过人" align="center" prop="firstSuggestLastBy" width="150"/>
      <el-table-column label="一审时间" align="center" prop="firstSuggestLastTime" width="150" :sortable="true"/>
      <el-table-column label="二审通过人" align="center" prop="secondSuggestLastBy" width="150"/>
      <el-table-column label="二审时间" align="center" prop="secondSuggestLastTime" width="150" :sortable="true"/>
      <el-table-column label="操作" fixed="right" width="200" align="center" class-name="small-padding">
        <template v-slot="scope">
          <el-button type="primary" size="mini" @click="repairInfo(scope.row.id)">
            查看详情
          </el-button>
          <el-popconfirm
              v-if="scope.row.status === 9"
              confirm-button-text='好的'
              cancel-button-text='不用了'
              icon="el-icon-info"
              icon-color="orange"
              title="确认驳回本条数据吗？"
              @confirm="examineBack(scope.row.id)"
          >
            <el-button v-permission="'crm:repair:examineBackOk'" type="warning" size="mini"
                       slot="reference">
              驳回
            </el-button>
          </el-popconfirm>

          <el-popconfirm
              v-if="scope.row.status === 0 || scope.row.status === -3"
              confirm-button-text='好的'
              cancel-button-text='不用了'
              icon="el-icon-info"
              icon-color="red"
              title="确认删除本条数据吗？"
              @confirm="deleteOrderRepair(scope.row.id)"
          >
            <el-button v-permission="'crm:repair:delete'" type="danger" size="mini"
                       slot="reference">
              删除
            </el-button>
          </el-popconfirm>

          <el-button v-permission="'crm:repair:toOrderManagement'" type="success" size="mini"
                     @click="toOrderManagement(scope.row.id)"
                     v-if="scope.row.status === 9">
            同步
          </el-button>
          <el-tooltip v-permission="'crm:repair:first'" content="审核可进行驳回,通过.驳回可以让学习顾问重新修改"
                      placement="left"
                      v-if="scope.row.status === 1">
            <el-button type="success" size="mini" @click="examineRepair(scope.row.id)">
              审核
            </el-button>
          </el-tooltip>
          <el-tooltip v-permission="'crm:repair:second'"
                      content="审核可进行驳回,通过.驳回可以让学习顾问重新修改" placement="left"
                      v-if="scope.row.status === 2">
            <el-button type="success" size="mini" @click="examineRepair(scope.row.id)">
              审核
            </el-button>
          </el-tooltip>
          <el-tooltip content="撤回后,订单可进行修改重新提交" placement="left"
                      v-if="(scope.row.status === 1 || scope.row.status === 2)">
            <el-button type="warning" size="mini" @click="backRepair(scope.row.id)">
              撤回
            </el-button>
          </el-tooltip>
          <el-tooltip content="订单在未提交前可进行修改" placement="left"
                      v-if="scope.row.status === 0 || scope.row.status === -3">
            <el-button type="error" size="mini" @click="updateRepair(scope.row.id)">
              修改
            </el-button>
          </el-tooltip>
          <el-tooltip content="提交后,订单将进入审批环节" placement="left"
                      v-if="scope.row.status === 0">
            <el-button type="primary" size="mini" @click="submitRepair(scope.row.id)">
              提交
            </el-button>
          </el-tooltip>
            <el-tooltip content="校验信息无问题,可申诉返回到驳回前的审批流程" placement="left"
                        v-if="scope.row.status === -3">
                <el-button class="el-button--warning"  type="primary" size="mini" @click="complaint(scope.row.id)">
                    申诉
                </el-button>
            </el-tooltip>
          <el-tooltip content="订单日志" placement="left"
                      v-permission="'crm:repair:log'">
            <el-button type="info" plain size="mini" @click="checkLog(scope.row.id)">
              操作日志
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        :v-show="queryParams.total>10"
        :total="queryParams.total"
        :page.sync="queryParams.current"
        :limit.sync="queryParams.size"
        @pagination="getList"
    />

    <ImportOrderRepair
        :show-obj="importObj.showObj"
        @getList="getList">
    </ImportOrderRepair>

    <OrderRepairDetail
        v-if="repairShow"
        @getList="getList"
        :pond-id="pondId"
        :dialogValue.sync="repairShow"
        :repair-id="repairId"
        :adviser-list="adviserList"
        :repairStatus.sync="repairStatus"></OrderRepairDetail>

    <el-dialog title="补录订单操作日志" :visible.sync="repairLogVisible">
      <el-table :data="repairLogArr">
        <el-table-column property="createByName" label="操作人" width="100"/>
        <el-table-column property="operateTypeStr" label="操作动作" width="150"/>
        <el-table-column property="orderNo" label="支付单号"/>
        <el-table-column property="opportunityId" label="机会ID" width="100"/>
        <el-table-column property="suggestText" label="评审意见"/>
        <el-table-column property="updateTime" label="操作时间"/>
      </el-table>
    </el-dialog>

  </div>
</template>

<script>
import * as api from "@/utils/api";
import * as repairApi from "@/api/crm/orderRepair"
import OrderRepairDetail from "@/components/order/OrderRepairDetail.vue"
import {getUserId} from "@/utils/auth";
import ImportOrderRepair from "@/components/order/ImportOrderRepair";
import * as  accountApi from "@/api/system/account";
import axios from "axios";
import * as dectApi from '@/api/system/dictionary'
import * as deptApi from "@/api/system/dept";
import * as searchApi from "@/api/system/searchDeploy";

export default {
  name: "OrderRepair",
  components: {
    OrderRepairDetail,
    ImportOrderRepair
  },
  props: {
    pondId:{
        type:Number,
        default:1
    }
  },
  data() {
    return {
      isShowBack: 0,
      withdraw: false,
      repairStatus: 0,
      repairId: 0,
      repairShow: false,
      payTimeArr: [],
      queryParams: {
        orderNo: null,
        goodsName: null,
        findType: 0,
        total: 0,
        current: 1,
        size: 10,
        status: null,
        startDateTime: null,
        endDateTime: null,
        joinActivity: '',
        handleOpenCourse: '',
      },
      repairList: [],
      loading: false,
      adviserList: [],
      accountList: [],
      possessList: [],
      searchRules: {
        opporId: [
          {min: 1, max: 9, message: '请输入正确的机会ID', trigger: 'blur'}
        ]
      },
      orderChannelList: [],
      classifyData: {},
      orderStatusList: [
        // {value: 0, label: '未提交/驳回', type: 'danger'},
        {value: 0, label: '未提交', type: 'danger'},
        {value: 1, label: '一审审批中', type: 'warning'},
        {value: 2, label: '财务核对中', type: 'warning'},
        {value: -3, label: '已驳回', type: 'danger'},
        {value: 9, label: '完成(未同步)', type: 'success'},
        {value: 10, label: '完成(已同步)', type: 'success'},
      ],
      collectionWayList: [],
      findTypeList: [
        {value: 0, label: "我的录单"},
        {value: 1, label: "审批录单"},
        {value: 2, label: "审核记录"},
        {value: 3, label: "全部"},
      ],
      importObj: {
        showObj: {
          isShow: false
        }
      },
      payAccountArr: [],
      deptArr: [],
      repairLogArr: [],
      repairLogVisible: false,
    };
  },
  created() {
    this.findByClassifyForMap();
    // window.addEventListener('keydown', this.keyboard, true)//开启监听键盘按下事件
    this.init();
    this.getAdviserList();
    this.getPossessList();
    this.getAccountList();
    this.getDeptList();
  },
  computed: {
    userId: function () {
      return getUserId()
    }
  },
  mounted() {
    this.getList()
    this.isShowBack = this.$route.query.isShowBack
    this.pondId = this.$route.query.pondId

  },
  methods: {
    keyboard(e) {
      if (e.keyCode === 13) {
        this.handleQuery()
      }
    },

    findByClassifyForMap() {
        let type = 'REPAIR_INFO';
        searchApi.findByClassifyForMap(type).then(res => {
        if (res.success) {
            this.classifyData = res.data;
        }
      });
    },
    // 初始化数据
    init() {
      dectApi.list({dictParent: 14}).then(resp => {
        if (resp.success) {
          this.payAccountArr = resp.data
        } else this.$message.error(resp.msg)
      })
      dectApi.list({dictParent: 15}).then(resp => {
        if (resp.success) {
          this.collectionWayList = resp.data
        } else this.$message.error(resp.msg)
      })
      dectApi.list({dictParent: 16}).then(resp => {
        if (resp.success) {
          this.orderChannelList = resp.data
        } else this.$message.error(resp.msg)
      })
    },
    /** 查询部门列表 */
    getDeptList() {
      deptApi.listDept().then(response => {
        let deptArr = response.data;
        for (let i in deptArr) {
          if (deptArr[i].parentId === 1) {
            this.deptArr.push(deptArr[i])
          }
        }
      });
    },
    /**
     * 导入录单
     */
    importOrderRepair() {
      this.importObj.showObj.isShow = true
    },
    /**
     * 导出录单数据
     */
    exportOrderRepair() {
      const loading = this.$loading({
        lock: true,
        text: '玩命加载中...请稍后...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      if (this.payTimeArr && this.payTimeArr[0]) {
        this.queryParams.startDateTime = this.payTimeArr[0]
        this.queryParams.endDateTime = this.payTimeArr[1]
      } else {
        this.queryParams.startDateTime = null
        this.queryParams.endDateTime = null
      }
      // 数据过滤
      let params = {}
      for (let i in this.queryParams) {
        if (this.queryParams[i] !== null && this.queryParams[i] !== void (0) && this.queryParams[i] !== '') {
          params[i] = this.queryParams[i]
        }
      }
      if (params.findType === void (0)) {
        params['findType'] = 5
      }
      // this.queryParams['submitId'] = this.submitId
      axios({
        method: 'post',
        url: `/api/manage/crm/order-repair/exportOrderRepair`,
        params: params,
        responseType: "blob",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
            "version": localStorage.getItem("_version"),
        },
      }).then(res => {
        let now = new Date();
        const blob = new Blob([res.data]);
        const fileName = '补录订单' + now.getFullYear() + now.getMonth() + 1 + now.getDate() + '.xlsx';
        const elink = document.createElement('a')
        elink.download = fileName;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
        loading.close();
      })
    },
    /**
     * 查看详情
     * @param repairId
     */
    repairInfo(repairId) {
      this.repairId = repairId
      this.repairStatus = 9;
      this.repairShow = true
    },
    /**
     * 申诉
     */
    complaint(repairId){
        this.repairId = repairId
        this.repairStatus = 10;
        this.repairShow = true
        console.log(111,this.repairStatus)
    },
    /**
     * 修改录单
     */
    updateRepair(repairId) {
      this.repairId = repairId
      this.repairStatus = 1;
      this.repairShow = true
    },
    /**
     * 审核
     */
    examineRepair(repairId) {
      this.repairId = repairId
      this.repairStatus = 2;
      this.repairShow = true
    },
    /**
     * 提交
     */
    submitRepair(row) {
      repairApi.submitRepairId(row).then(res => {
        if (res.success) {
          this.$message.success(res.msg)
          this.getList();
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    /**
     * 撤回
     */
    backRepair(repairId) {
      repairApi.backRepair(repairId).then(res => {
        if (res.success) {
          this.$message.success(res.msg)
          this.getList();
        } else {
          this.$message.error(res.msg)
        }
      });
    },
    // 新增
    showDetail() {
      this.repairId = 0
      this.repairStatus = 0
      this.repairShow = true
    },
    //金额格式化
    numberformat(number) {
      number = (number + '').replace(/[^0-9+-Ee.]/g, '');
      var n = !isFinite(+number) ? 0 : +number,
          prec = !isFinite(+2) ? 0 : Math.abs(2),
          s = '',
          toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.floor(n * k) / k;
          };
      s = (prec ? toFixedFix(n, prec) : '' + Math.floor(n)).split('.');
      if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
      }
      return s.join('.');
    },
    /**
     * 获取唯一值配置
     */
    getPossessList() {
      api.possessList().then(res => {
        this.possessList = res.data
      })
    },
    getAccountList: function () {
      accountApi.list().then(res => {
        if (res.success) {
          this.accountList = res.data
        }
      })
    },
    /**
     * 获取学习顾问列表
     */
    getAdviserList: function () {
      accountApi.adviserList().then(res => {
        if (res.success) {
          this.adviserList = res.data
        }
      })
    },
    /** 查询参数配置列表 */
    getList() {
      this.loading = true
      if (this.payTimeArr && this.payTimeArr[0]) {
        this.queryParams.startDateTime = this.payTimeArr[0]
        this.queryParams.endDateTime = this.payTimeArr[1]
      } else {
        this.queryParams.startDateTime = null
        this.queryParams.endDateTime = null
      }
      // this.queryParams['submitId'] = this.submitId
      repairApi.listPage(this.queryParams).then(resp => {
        if (resp.success) {
          this.repairList = resp.data.records
          this.queryParams.total = resp.data.total
          this.queryParams.size = resp.data.size
          this.queryParams.current = resp.data.current
          this.loading = false
        } else {
          this.$message.error(resp.msg);
          if (this.queryParams.findType) {
            this.queryParams.findType = 0;
            this.getList();
          }
          this.loading = false;
        }

      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.$refs['queryForm'].validate((valid) => {
        if (valid) {
          this.queryParams.page = 1;
          this.getList();
        } else {
          return false;
        }
      });

    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.payTimeArr = [];
      this.queryParams.startDateTime = null;
      this.queryParams.endDateTime = null;
      this.handleQuery();
    },
    examineBack(repairId) {
      repairApi.examineBack(2, repairId).then(res => {
        if (res.success) {
          this.$message.success(res.msg)
          this.getList()

        } else {
          this.$message.error(res.msg)
        }
      })
    },
    toOrderManagement(repairId) {
      repairApi.toOrderManagement(repairId).then(res => {
        if (res.success) {
          this.$message.success(res.msg)
          this.getList()

        } else {
          this.$message.error(res.msg)
        }
      })
    },
    deleteOrderRepair(orderRepairId) {
      repairApi.deleteOrderRepair(orderRepairId).then(res => {
        if (res.success) {
          this.$message.success(`删除成功!`)
          this.getList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    goBack() {
      //跳转到上一次浏览的页面
      this.$router.go(-1)
    },
    checkLog(opporId) {
      repairApi.checkLog(opporId).then(resp => {
        if (resp.success) {
          this.repairLogVisible = true;
          this.repairLogArr = resp.data;
        } else this.$message.error("补录日志发生错误,请联系管理员")
      })
    }
  }
};
</script>
<style lang="less" scoped>
.small-padding button {
  margin: 3px;
}

.el-form-item {
  margin-bottom: 10px
}
</style>
